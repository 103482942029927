import React from 'react';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import {
  Options,
  Section,
  SectionHeadline,
  SectionSubheadline,
} from '../../common';
import { FormSpinner } from '../../checkout/entry/form';
import { BIKE_MODELS, CHECKOUT_UNAVAILABLE_REASONS } from '@/config/constants';
import type { BikeModel } from '@/webapi/gen/graphql';
import { modelName } from '@/helpers/i18n';
import { Vehicle } from '../entry/vehicle/vehicle';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

export const CheckoutUnavailableSuccess = ({ model, reason }) => {
  const intl = useIntl();
  const vehicle = BIKE_MODELS[model];

  const {
    isPatching,
    checkoutState,
    config: { models },
    patchCheckout,
    modelAvailability,
  } = useCheckoutState();

  const handleChangeModel = async (
    chosen: BikeModel,
    preferredVehicle: BikeModel
  ): Promise<void> => {
    await patchCheckout(
      { id: checkoutState.id, model: chosen },
      { preferredVehicle }
    ).then(() => {
      window.location.pathname = `/${intl.locale}/c/`;
    });
  };

  const availableModels = new Set(modelAvailability.map((a) => a.model));
  const otherAvailableModels = models
    .map((offering) => offering.model)
    .filter((m) => m !== model)
    .filter((m) => availableModels.has(m));
  const vehicleStr = vehicle
    ? modelName(intl, model)
    : intl.formatMessage({
        id: 'checkout-unavailable.literal-vehicle',
        defaultMessage: 'vehicle',
      });

  const headline = () => {
    switch (reason) {
      case CHECKOUT_UNAVAILABLE_REASONS.AREA_UNAVAILABLE:
        return (
          <FormattedMessage
            id="checkout-unavailable.success-no-area-heading"
            defaultMessage="Thanks, we'll let you know once Dance is available in your area."
          />
        );
      default:
        return (
          <FormattedMessage
            id="checkout-unavailable.success-heading"
            defaultMessage="Thanks, you're on the list for a {vehicleStr}."
            values={{ vehicleStr }}
          />
        );
    }
  };

  if (isPatching) {
    return <FormSpinner />;
  }

  return (
    <>
      <Section>
        <SectionHeadline>{headline()}</SectionHeadline>

        {!!otherAvailableModels.length &&
          reason === CHECKOUT_UNAVAILABLE_REASONS.VEHICLE_UNAVAILABLE && (
            <>
              <SectionSubheadline>
                <FormattedMessage
                  id="checkout-unavailable.unavailable-subheadline"
                  defaultMessage="In case you want to choose a different vehicle in the meantime, we can swap it with your preferred one once they are available."
                />
              </SectionSubheadline>

              <Options>
                {otherAvailableModels.map((otherModel) => {
                  const { maxHeight, minHeight } = BIKE_MODELS[otherModel];
                  const isMoped = otherModel === 'NIUNQISPORT';

                  return (
                    <Vehicle
                      key={otherModel}
                      area={checkoutState.danceArea}
                      model={otherModel}
                      onSelect={() => handleChangeModel(otherModel, model)}
                      isMoped={isMoped}
                      minHeight={minHeight}
                      maxHeight={maxHeight}
                    />
                  );
                })}
              </Options>
            </>
          )}
      </Section>

      <div className="continue-button">
        <Button variant="primary" onClick={() => navigate(-1)}>
          {!!otherAvailableModels.length ? (
            <FormattedMessage
              id="checkout-unavailable.back-to-all-vehicles"
              defaultMessage="Go back to all vehicles"
            />
          ) : (
            <FormattedMessage
              id="checkout-unavailable.go-back"
              defaultMessage="Go back"
            />
          )}
        </Button>
      </div>
    </>
  );
};
