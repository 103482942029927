import { IntlShape } from 'gatsby-plugin-react-intl';
import * as yup from 'yup';

export const createValidationSchema = (intl: IntlShape) => {
  return yup.object({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: 'checkout-form.malformed-email-error',
          defaultMessage: 'Please enter a valid email address.',
        })
      )
      .required(
        intl.formatMessage({
          id: 'checkout-form.required-error',
          defaultMessage: 'Please complete this field.',
        })
      )
      .nullable(),
    firstname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'checkout-form.required-error',
          defaultMessage: 'Please complete this field.',
        })
      )
      .nullable(),
    lastname: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'checkout-form.required-error',
          defaultMessage: 'Please complete this field.',
        })
      )
      .nullable(),
    postcode: yup
      .string()
      .nullable()
      .required(
        intl.formatMessage({
          id: 'checkout-form.required-error',
          defaultMessage: 'Please complete this field.',
        })
      ),
  });
};
