import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '@/webapi/client';
import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import { WithCheckout } from '../../hooks/useCheckoutState';
import { CheckoutUnavailable } from '../../components/checkout/unavailable/checkout-unavailable';

const CheckoutUnavailablePage = ({ location }) => {
  const model =
    location.state && location.state.model ? location.state.model : undefined;
  const reason =
    location.state && location.state.reason ? location.state.reason : null;

  return (
    <ApolloProvider client={client}>
      <WithCheckout>
        <Layout withCheckoutFooter={false} model={model}>
          <Seo title="Dance" />
          <CheckoutUnavailable model={model} reason={reason} />
        </Layout>
      </WithCheckout>
    </ApolloProvider>
  );
};

export default CheckoutUnavailablePage;
